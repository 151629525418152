<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit User</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'users'}">Users</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
        </nav>
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:keyup.enter="updateUser($event)">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Email
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <ValidationProvider
                                name="email"
                                rules="required|email|max:198"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="emailHelpInline"
                                    v-model="user.email"
                                    autocomplete="off"
                                />
                                <small v-if="errors.length != 0" id="emailHelpInline" class="text-danger field">
                                    {{
                                    errors[0]
                                    }}
                                </small>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Password
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="password"
                                class="form-control"
                                placeholder="********"
                                aria-describedby="passwordHelpInline"
                                v-model="user.password"
                                autocomplete="off"
                            />
                            <small id="passwordHelpInline" class="text-muted field">(Optional)</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            First Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <ValidationProvider
                                name="firstname"
                                rules="required|min:2"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="fnameHelpInline"
                                    v-model="user.first_name"
                                    autocomplete="off"
                                    @change="textTitleCase($event, 'first_name')"
                                />
                                <small
                                    v-if="errors.length == 0"
                                    id="fnameHelpInline"
                                    class="text-muted field"
                                >Minimum 2 characters</small>
                                <small v-else id="fnameHelpInline" class="text-danger field">
                                    {{
                                    errors[0]
                                    }}
                                </small>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">Last Name</label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="lnameHelpInline"
                                v-model="user.last_name"
                                autocomplete="off"
                                @change="textTitleCase($event, 'last_name')"
                            />
                            <small id="lnameHelpInline" class="text-muted field">(Optional)</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Roles
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-group>
                                <ValidationProvider
                                    name="roles"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <b-form-checkbox-group
                                        id="checkbox-group-1"
                                        v-model="user.role"
                                        :options="roles"
                                        name="flavour-1"
                                        stacked
                                        required
                                    ></b-form-checkbox-group>
                                    <small
                                        v-if="errors.length == 0"
                                        id="rolesHelpInline"
                                        class="text-muted field"
                                    >Select one or more Roles</small>
                                    <small v-else id="rolesHelpInline" class="text-danger field">
                                        {{
                                        errors[0]
                                        }}
                                    </small>
                                </ValidationProvider>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-group row" style="float:right !important;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateUser()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            user: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                role: []
            },
            roles: [],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    created() {
        this.access = this.hasPermission("CAN_UPDATE_USER");
        this.getRoles();
        this.getUser();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        textTitleCase(event, key) {
            this.user[key] = this.strTitleCase(event.target.value);
        },
        getUser: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = { id: this.$route.params.id };
            this.axios
                .get(this.$api.get_users, query)
                .then(response => {
                    this.user = response.data.data[0];
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    // this.api_error = "Something went wrong.";
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        updateUser: function() {
            this.startProgressBar();
            this.axios
                .post(this.$api.update_user, this.user, this.getAuthHeaders())
                .then(() => {
                    this.$router.push({ name: "users" });
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        getRoles: function() {
            this.startProgressBar();
            this.axios
                .get(this.$api.get_roles, this.getAuthHeaders())
                .then(response => {
                    const result = response.data;
                    result.data.forEach(role => {
                        this.roles.push({ text: role, value: role });
                    });
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.roles = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style> 